import React, { memo } from "react";
import { Card, CardActionArea, CardContent, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import OverflowTypography from "../OverflowTypography";
import QrCodeIcon from "@material-ui/icons/QrCode";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import WeekendOutlinedIcon from "@material-ui/icons/WeekendOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ErrorOutlineIco from "@material-ui/icons/ErrorOutline";

import {
  isDeviceOnline,
  lastOnlineString,
  DEVICE_DEPLOYMENT_STATE
} from "../../utils/deviceUtils";
import {
  isDeviceLive,
  DEVICE_PLATFORMS,
} from "../../services/DeviceService";
import { useDeviceOverallStatus } from "../../services/ProjectService";
import { areDictsEqual } from "../../utils/generalUtils";

import { DeviceStrings } from "../../strings";

const CompliantIcon = ({ compliant }) =>
  compliant === DEVICE_DEPLOYMENT_STATE.COMPLIANT ? (
    <Tooltip title={DeviceStrings.COMPLIANCE_TITLE_COM}>
      <AssignmentTurnedInOutlinedIcon color="primary" fontSize="small" />
    </Tooltip>
  ) : (
    <Tooltip title={DeviceStrings.COMPLIANCE_TITLE_NON}>
      <AssignmentLateOutlinedIcon fontSize="small" />
    </Tooltip>
  );

const OnlineIcon = ({ online, error }) => {
  let title = DeviceStrings.ONLINE_TITLE_ERROR;
  let color = "inherit";
  let Icon = ErrorOutlineIco;
  if (!error) {
    if (online === true) {
      title = DeviceStrings.ONLINE_TITLE_ON;
      Icon = WifiIcon;
    } else if (online === false) {
      title = DeviceStrings.ONLINE_TITLE_OFF;
      color = "disabled";
      Icon = WifiOffIcon;
    }
  }
  return (
    <Tooltip title={title}>
      <Icon fontSize="small" color={color} />
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => {
  const border = `solid 1px #E0E0E0`;
  const disabledBackgroundColor = "#F0F0F0";
  return {
    card: (online) => ({
      backgroundColor: online ? "white" : disabledBackgroundColor,
      color: online ? theme.palette.text.primary : theme.palette.text.secondary,
      flexGrow: 1,
    }),
    content: {
      padding: 0,
      display: "flex",
    },
    left: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      borderRight: border,
      padding: theme.spacing(1),
    },
    right: {
      flexGrow: 1,
      width: 1,
      position: "relative",
    },
    id: {
      padding: theme.spacing(1),
    },
    stage: {
      borderTop: border,
      padding: theme.spacing(1),
    },
    checkbox: (online) => ({
      backgroundColor: online ? "white" : disabledBackgroundColor,
      position: "absolute",
      right: 4,
      top: 4,
    }),
  };
});

/**
 * sample config
 */
// const config = {
//   onClick: (deviceId) => {},
//   onSelect: (deviceId) => {},
//   showSelect: true,
//   selected: true,
// };

// assume config doesn't change
const areCardsEqual = (props1, props2) =>
  areDictsEqual(props1.info, props2.info) &&
  areDictsEqual(props1.config, props2.config);

const DeviceCard = memo(({ info, projectId, config }) => {
  const online = isDeviceOnline(info);
  const live = isDeviceLive(info);
  const classes = useStyles(!!online);
  const isQrId = info?.platform === DEVICE_PLATFORMS.QRID;

  const onClick = config?.onClick;
  const onSelect = config?.onSelect;
  const showSelect = config?.showSelect;

  const overallStatus = useDeviceOverallStatus(projectId, info?.deviceId);
  const status = overallStatus?.checkComplianceOnly
    ? DeviceStrings.COMPLIANCE_CHECK_OVERALL_STATUS[overallStatus?.status]
    : DeviceStrings.DEPLOY_OVERALL_STATUS[overallStatus?.status];
  const lastOnline = lastOnlineString(info) ?? DeviceStrings.NEVER;

  const onClickInternal = () => {
    if (onClick) onClick(info.deviceId);
  };

  const onSelectInternal = (e) => {
    e.stopPropagation();
    if (onSelect) onSelect(info);
  };

  const liveIcon = (
    <>
      <CompliantIcon compliant={info.state} />
      <OnlineIcon online={online} />
    </>
  );

  const notLiveIcon = (
    <>
      <WeekendOutlinedIcon />
    </>
  );

  const qrIcon = (
    <>
      <QrCodeIcon />
      <OnlineIcon online={online} />
    </>
  );

  const iconContent = isQrId ? qrIcon : live ? liveIcon : notLiveIcon;

  const infoStage = (
    <OverflowTypography variant="body2" color="textSecondary" noWrap>
      {status || info.stage ||  "\u00A0"}
    </OverflowTypography>
  );

  const qrStage = (
    <OverflowTypography variant="body2" color="textSecondary" noWrap>
      {DeviceStrings.LAST_ONLINE + ' '}
      {lastOnline}
    </OverflowTypography>
  );

  const stageContent = isQrId ? qrStage : infoStage;

  const checkboxContent = showSelect && (
    <div className={classes.checkbox} onClick={onSelectInternal}>
      {config?.selected ? (
        <CheckBoxIcon color="primary" />
      ) : (
        <CheckBoxOutlineBlankIcon color="primary" />
      )}
    </div>
  );

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onClickInternal}>
        <CardContent className={classes.content}>
          <div className={classes.left}>{iconContent}</div>
          <div className={classes.right}>
            {checkboxContent}
            <div className={classes.id}>
              <OverflowTypography variant="caption" noWrap>
                {info.deviceId}
              </OverflowTypography>
              <OverflowTypography variant="body2" color="textSecondary" noWrap>
                {info.storeId || "\u00A0"}
              </OverflowTypography>
              {!live && (
                <OverflowTypography
                  variant="body2"
                  color="textSecondary"
                  noWrap
                >
                  {info.retailerName}
                </OverflowTypography>
              )}
            </div>
            {live && <div className={classes.stage}>{stageContent}</div>}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}, areCardsEqual);

export default DeviceCard;
