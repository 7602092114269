import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { selectMenuProps } from "../configs/propsConfig";

const MySelect = ({ title, value, onChange, options, titleKey, disabled }) => {
  return (
    <div style={{ display: "flex" }}>
      <FormControl style={{ flexGrow: 1, margin: 5 }}>
        {title && <InputLabel shrink>{title}</InputLabel>}
        <Select
          value={value}
          onChange={onChange}
          MenuProps={selectMenuProps}
          style={{ padding: 12 }}
          disabled={disabled}
        >
          {options &&
            Object.entries(options).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {titleKey ? v[titleKey] : (v.title ?? v.name) || v}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MySelect;
